  html, body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin: 0;
    padding: 1em;
    background-color: #666;
  }
  h1 {
    font-size: 4em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h1 a {
    text-decoration: none;
    color: #333;
  }
  h1 a:hover {
    color: #f90;
  }
  .container {
    list-style: none;
    column-gap: 1em;
    padding: 0;
    column-count: 5;
  }
  .card {
    position: relative;
    border: none;
    min-height: 128px;
    width: 100%;
    margin-bottom: 1em;
    -webkit-column-break-inside: avoid;
    /* width: 100%; */
    /* padding: 0; */
    /* margin: 0; */
    /* box-sizing: border-box; */
    break-inside: avoid;
    /* margin-bottom: 1em; */
  }
  .card {
    text-decoration: none;
  }
  .card img {
    width: 100%;
    border: 1px solid #333;
    border-radius: 1em;
  }
  .card:hover img {
    filter: brightness(0.95) saturate(1.25);
    border: 1px solid #ccc;
  }
  ul.nav {
    list-style:none;
    margin:0;
  }
  ul.nav li {
    display:inline-block;
    margin:0;
    padding:0.5em;
  }
  ul.nav a {
    font-size: 2em;
    font-weight: bold;
    color: #333;
    text-decoration: none;
  }
  ul.nav a:hover {
    color: #f90;
  }
